/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import DOMPurify from 'dompurify';
import { Col, Row } from 'reactstrap';
import CryptoJS from 'crypto-js';
import pdfImg from '../../assets/images/pdf.png';
import excelImg from '../../assets/images/excel.png';
import { COOKIE_SECRET_KEY } from '../constants/ConstantsData';

export const getDateFormat = (date = new Date(), format = 'YYYY-MM-DD') => {
  const returnDate = moment(date).format(format);
  return returnDate;
};

export const getDDMMYYYYDateFormat = (
  date = new Date(),
  format = 'DD/MM/YYYY'
) => {
  const returnDate = moment(date).format(format);
  return returnDate;
};

export const getUTCFormat = (date = new Date(), format = 'YYYY-MM-DD') => {
  const returnDate = moment.utc(date).format(format);
  return returnDate;
};

export const getDateDifference = (
  past, // true(day of start time), false(day of end time)
  type, // 'days' | 'months' | 'years'
  duration, // 1,2,3
  fromDate = new Date(),
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  let alteredDate;
  const targetedDate = moment(fromDate);
  if (past) {
    alteredDate = moment(targetedDate).subtract(duration, type).startOf('day');
  } else {
    alteredDate = moment(targetedDate).add(duration, type).endOf('day');
  }
  return getUTCFormat(alteredDate, format);
};

export const toDateWithOutTimeZone = (date) => {
  const tempTime = date.split(':');
  const dt = new Date();
  dt.setHours(tempTime[0]);
  dt.setMinutes(tempTime[1]);
  dt.setSeconds(tempTime[2]);
  return dt;
};

export const getMonthFormat = (date = new Date(), format = 'MMM DD, YYYY') => {
  const returnDate = moment(date).format(format);
  return returnDate;
};

export const getTimeFormat = (date = new Date(), format = 'h:mm A') => {
  const returnDate = moment(date).format(format);
  return returnDate;
};

export const getDateandTimeFormat = (
  date = new Date(),
  format = 'MMM DD, YYYY, h:mm a'
) => {
  const returnDate = moment(date).format(format);
  return returnDate;
};

export const getApiTimeFormat = (date = new Date(), format = 'HH:mm') => {
  const returnDate = moment(date).format(format);
  return returnDate;
};

export const listFormatter = (data, logic) => {
  const formattedData = data.map(logic);
  return formattedData;
};

export const validationMsg = (errorObject) => {
  let errorMsg = null;
  if (errorObject) {
    if (errorObject.type === 'required') {
      errorMsg = 'This field is required.';
    } else if (errorObject.type === 'pattern' && errorObject.message) {
      errorMsg = errorObject.message;
    } else if (errorObject.type === 'file' && errorObject.message) {
      errorMsg = errorObject.message;
    }
  }
  return errorMsg;
};

export const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

export const timeago = (dateTime) => {
  let loggedTime = '';
  // eslint-disable-next-line no-debugger
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'Just now',
      ss: '%d seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: '1 hour',
      hh: '%d hours',
      d: 'Yesterday',
      dd: '%d days'
    }
  });
  const datetimes = new Date(dateTime).toUTCString(); //  'Mon, 25 Sep 2022 03:44:25 GMT';
  const differenceInTime = new Date(dateTime).getTime() - new Date().getTime();
  const DifferenceInDays = differenceInTime / (1000 * 3600 * 24);
  if (Math.abs(DifferenceInDays) < 5) {
    loggedTime = moment(new Date(datetimes), 'YYYY-MM-DD HH:mm:ss').fromNow();
    if (loggedTime === 'Yesterday ago') {
      loggedTime = 'Yesterday';
    }
    if (loggedTime === 'Just now ago') {
      loggedTime = 'Just now';
    }
  } else {
    loggedTime = moment(new Date(datetimes)).format('MMM DD YYYY, h:mm a');
  }
  return loggedTime;
};

export const filterLinks = (content) => {
  const str = content;
  const pattern1 =
    // eslint-disable-next-line no-useless-escape
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const str1 = str.replace(pattern1, "<a href='$1' target='_blank'>$1</a>");

  // eslint-disable-next-line no-useless-escape
  const pattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  const str2 = str1.replace(
    pattern2,
    '$1<a target="_blank" href="http://$2">$2</a>'
  );
  const parser = new DOMParser();
  const doc = parser.parseFromString(str2, 'text/html');
  return DOMPurify.sanitize(doc.body.innerHTML, { ADD_ATTR: ['target'] });
};

export const fileToDataURL = async (event) => {
  const returnPromise = new Promise((resolve, reject) => {
    let file = event;
    if (event.target) {
      const { target } = event.target;
      const fileList = target.files;
      // eslint-disable-next-line prefer-destructuring
      file = fileList[0];
    }
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const result = reader.result.toString();
        resolve(result);
      };
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('File is required.');
    }
  });
  return returnPromise;
};

export const convertToFormData = (apiValues) => {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(apiValues)) {
    let value = apiValues[key];
    if (Array.isArray(value)) {
      value = JSON.stringify(value);
    } else if (
      value &&
      typeof value === 'object' &&
      Object.keys(value).length
    ) {
      value = JSON.stringify(value);
    }
    formData.append(key, value);
  }
  return formData;
};

export const addThumbnail = (filename) => {
  let image;
  const fileExt = filename.split('.').pop();
  if (fileExt === 'pdf') {
    image = pdfImg;
  } else if (fileExt === 'xlsx' || fileExt === 'xls') {
    image = excelImg;
  } else {
    image = filename;
  }
  return image;
};

export const attachmentPreview = (attachments, removeFileHandler) => {
  const handleDownload = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  return (
    <div className="mt-3">
      <Row className="">
        <h6 className="text-primary font-semi-bold mb-3">Attachment</h6>
        {attachments.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={index} sm="auto">
            <div className="attach-img-container mb-3 ">
              <Row className="p-2 w-100 m-0 ">
                <Col xs={10}>
                  {typeof item === 'string' ? (
                    <>
                      <img
                        className="img-fluid img-attachment"
                        src={item ? addThumbnail(`${item}`) : ''}
                        alt="attachments"
                        crossOrigin="anonymous"
                      />
                      <div className="mt-2 text-char-limit-15">
                        <span className="fs-6 font-regular ">
                          <i className="">
                            {
                              item
                                .substring(item.lastIndexOf('/') + 1)
                                .split(/_(.*)/s)[1]
                            }
                          </i>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        className="img-fluid img-attachment"
                        src={item.url ? addThumbnail(`${item.url}`) : ''}
                        alt="attachments"
                        crossOrigin="anonymous"
                      />
                      <div className="mt-2 text-char-limit-15">
                        <span className="fs-6 font-regular ">
                          <i className="">
                            {
                              item.url
                                .substring(item.url.lastIndexOf('/') + 1)
                                .split(/_(.*)/s)[1]
                            }
                          </i>
                        </span>
                      </div>
                    </>
                  )}
                </Col>
                <Col
                  xs={2}
                  className="d-flex flex-column align-items-end justify-content-between">
                  {typeof item === 'string' ? (
                    <button
                      className="btn btn-link p-0"
                      onClick={() =>
                        handleDownload(
                          item,
                          item
                            .substring(item.lastIndexOf('/') + 1)
                            .split(/_(.*)/s)[1]
                        )
                      }>
                      <i className="bi bi-download" />
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-link p-0"
                        onClick={() =>
                          handleDownload(
                            item.url,
                            item.url
                              .substring(item.url.lastIndexOf('/') + 1)
                              .split(/_(.*)/s)[1]
                          )
                        }>
                        <i className="bi bi-download" />
                      </button>
                      <button
                        className="btn btn-link p-0 mt-3"
                        onClick={() => removeFileHandler(item.id)}>
                        <i className="bi bi-trash" />
                      </button>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export const onViewProfile = (employeeId, navigateRef) => {
  navigateRef(`/profile/${employeeId}`);
};

export const getDefaultTagUsers = (users, loginUser, isGeneral) => {
  const mentorsArchitechtsList = users?.filter(
    (user) =>
      (user.designation === 'architect' ||
        user.designation === 'mentor' ||
        isGeneral) &&
      user.id !== loginUser.employee_id
  );
  return mentorsArchitechtsList;
};

export const getAutomaticInputHeight = (inputRef, defaultHeight) => {
  let height = defaultHeight;
  if (inputRef[inputRef.length - 1]?.scrollHeight > defaultHeight) {
    height = inputRef[inputRef.length - 1]?.scrollHeight;
  } else {
    height = defaultHeight;
  }
  return height;
};

export const filterTeamByRoles = (userList, roleList) => {
  const mentees = userList.filter(
    // (team) => team.designation.toUpperCase() === 'MENTEE'
    (team) => roleList.includes(team.designation.toUpperCase())
  );
  return listFormatter(mentees, (team) => ({
    id: team?.employee_id,
    value: team?.name,
    label: team?.name,
    email: team?.email,
    profile_image: team?.profile_image
  }));
};

export const removeEmptyValuesFromObject = (obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      // eslint-disable-next-line no-unused-vars
      ([_, value]) => value !== null && value !== undefined
    )
  );

export const encryptCookieValue = (value) => {
  try {
    const encryptedValue = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      COOKIE_SECRET_KEY
    ).toString();
    return encryptedValue;
  } catch (error) {
    console.error('Error encrypting value:', error);
    return null;
  }
};

export const decryptCookieValue = (encryptedValue) => {
  try {
    if (encryptedValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, COOKIE_SECRET_KEY);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  } catch (error) {
    console.error('Error decrypting cookie value:', error);
  }
  return undefined;
};

export const openUrlInNewPage = (url) => {
  window.open(url, '_blank');
};
